<template>
	<div>
		<myheader></myheader>
		<div class="usermain">
			<div class="main">
				<footleft></footleft>
				<div class="userright">
					  <router-view/>
				</div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'
import footleft from '@/components/footleft.vue'
import {aboutAPI} from "@/api/footnav"
export default {

  components: {
    myheader, myfooter, myright, footleft
  },
  data () {
    return {

    }
  },
  created(){
	
	},
  methods: {
	  getabout(){
		aboutAPI().then(res=>{

		})
	}
  }
}
</script>

<style lang="scss" scoped="scoped">
.usermain{
	background-color: #f9f9f9;
	padding-bottom: 20px;
	padding-top: 20px;
	.main{
		width: 1200px;
		margin:0px auto;
		display: flex;

		.userright{
			margin-left: 30px;
			flex: 1;
			min-height: 570px;

			border-radius: 6px;

		}
	}

}

</style>
