<template>
	<div class="userleft">
		
		<ul>
			<li v-for="(item,i) in usernav" :key="i">
				
				 <router-link :to="item.to">
					 <strong>{{item.name}}</strong>
				 </router-link>
				 
			</li>
		</ul>
	</div>
</template>

<script>

export default {

  data () {
    return {
      usernav: [
        {
          name: '关于趣游',
          to: '/quyou/about',
        }, {
          name: '公关策划',
          to: '/quyou/PR'
        }, {
          name: '广告服务',
          to: '/quyou/advertisement'
        }, {
          name: '联系我们',
          to: '/quyou/contact'
        }, {
          name: '意见反馈',
          to: '/quyou/opinion'
        }, {
          name: '交换链接',
          to: '/quyou/exchangelinks'
        }, {
          name: '隐私保护',
          to: '/quyou/privacy'
        }, {
          name: '加入趣游',
          to: '/quyou/joinus'
        }, {
          name: '使用协议',
          to: '/quyou/useProtocol'
        }, {
          name: '隐私条款',
          to: '/quyou/privacyClause'
        }
      ]

    }
  },
  created () {

  },
  methods: {
    touserurl (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">

.userleft{
	width: 150px;
	background: #ffffff;
	border-radius: 6px;
	text-indent: 15px;
	padding: 10px 0;
	h3{
		font-size: 17px;
		height: 35px;
		font-weight: 500;
		color: #ff7c00;
		line-height: 38px;
		cursor: pointer;
	}
	li{
		border-bottom: 1px solid #e6e6e6;
		padding: 5px 0;
		a{
			cursor: pointer;
			font-size: 12px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #666666;
			line-height: 30px;
			strong{
				color: #333;
			}
		}
		p:hover{
			color: #ff7c00;
		}
		p:hover strong{
			color: #ff7c00;
		}
	}
	li:last-child{
		border: none;
	}
	
}
.router-link-exact-active ,.router-link-active{
	  color: red !important;
	//   background-color: red;

	}
</style>
